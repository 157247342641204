<template>
    <b-modal
        :id="modalId"
        :ref="modalId"
        scrollable
        centered
        dialog-class="ml-3 custom-modal-dialog-class"
        body-class="custom-modal-body-class"
        hide-header
        hide-footer
        @hide="handleHide"
        @shown="handleShown"
    >
        <div :id="`${modalId}-selectlist`" tabindex="-1" class="selectlist__content-wrapper">
            <ul v-for="option in options" :key="option.group" role="listbox" class="selectlist__content">
                <li id="group-activity" class="selectlist__element">
                    <span class="selectlist__option selectlist__option--disabled selectlist__option--group">
                        <div class="text-body font-weight-bold">{{ option.group }}</div>
                    </span>
                </li>

                <li
                    v-for="(activity, index) in option.items"
                    :id="`activity-${activity.key}`"
                    :key="`activity-${index}`"
                    role="option"
                    class="selectlist__element"
                    @click="selectItem(activity)"
                >
                    <span class="selectlist__option">
                        <div class="f-13 d-flex justify-content-between align-items-center">
                            <div>
                                <IconLoader :icon="toTitleCase(activity.key)" size="xl" />
                            </div>
                            <div>{{ activity.name }}</div>
                        </div>
                    </span>
                </li>
            </ul>
        </div>
    </b-modal>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import { mapGetters } from 'vuex'
import { element_types } from '../../objects/Document'
import { EventBus } from '../../common/EventBus'
import { toTitleCase } from '../../helpers/stringUtils'
import IconLoader from '../../stories/components/icons/IconLoader.vue'

interface Item {
    key: string
    name: string
}

interface Option {
    group: string
    items: Item[]
}

interface Data {
    modalId: string
    options: Option[]
    activities: any[]
    loading: boolean
    defaultScrollPosition: number
    scrollTo: number
    callback: (item: Item) => void
}

interface WorkSheetElement {
    key: string
    name: string
    type: string
    title: string
    instructions: string
}

export default defineComponent({
    name: 'AddItemModal',
    components: {
        IconLoader,
    },
    props: {
        show: {
            type: Boolean,
            default: false,
        },
    },
    data(): Data {
        return {
            modalId: 'add-item-modal',
            options: [],
            activities: [],
            loading: false,
            defaultScrollPosition: 0,
            scrollTo: -1,
            callback: (item: Item) => {},
        }
    },
    computed: {
        ...mapGetters({
            documentTypes: 'document/allActivities',
            selected: 'document/selected',
        }),
        questionScrollPosition(): number {
            const el = document.getElementById('activity-fill_in_the_blank')?.getBoundingClientRect()
            const container = this.getScrollContainerElement()?.getBoundingClientRect()

            if (!el || !container) return 0

            return el.y - container.height + container.top
        },
    },
    created() {
        this.instantiateSelector()
        EventBus.$on('open-add-item-modal', (val: () => {}) => this.openModal(val))
    },
    methods: {
        toTitleCase,
        instantiateSelector() {
            this.activities = this.documentTypes.map((element: WorkSheetElement) => ({
                key: element.key,
                name: element.name,
            }))

            this.options.push(
                {
                    group: 'Organization',
                    items: [],
                },
                {
                    group: 'Directions',
                    items: [],
                },
                {
                    group: 'Questions',
                    items: [],
                },
                {
                    group: 'Activites',
                    items: [],
                },
                {
                    group: 'Special',
                    items: [],
                },
            )
            element_types.forEach((element) => {
                if (['section_header', 'divider', 'blank_space', 'page_break'].includes(element.key)) {
                    this.options[0].items.push(element)
                }
                if (['instruction', 'basic_text', 'checklist'].includes(element.key)) {
                    this.options[1].items.push(element)
                }
                if (['word_bank'].includes(element.key)) {
                    this.options[4].items.push(element)
                }
            })
            this.activities.forEach((activity) => {
                if (['checklist'].includes(activity.key)) {
                    this.options[1].items.push(activity)
                }
                if (['multiple_choice', 'open_response', 'fill_in_the_blank'].includes(activity.key)) {
                    this.options[2].items.push(activity)
                }
                if (['matching', 'word_scramble', 'handwriting'].includes(activity.key)) {
                    this.options[3].items.push(activity)
                }
            })
        },
        openModal(callback: (item: Item) => void) {
            this.callback = callback
            this.$bvModal.show(this.modalId)
        },
        async selectItem(item: Item) {
            if (this.callback) {
                this.callback(item)
            }
            this.$bvModal.hide(this.modalId)
        },
        getScrollContainerElement(): HTMLElement | null {
            return document.getElementById(`${this.modalId}-selectlist`)
        },
        getScrollPosition(): number {
            return this.getScrollContainerElement()?.scrollTop || this.defaultScrollPosition
        },
        handleHide() {
            this.scrollTo = this.getScrollPosition()
        },
        handleShown() {
            this.getScrollContainerElement()?.scrollTo(
                this.defaultScrollPosition,
                this.scrollTo === -1 ? this.questionScrollPosition : this.scrollTo,
            )
        },
    },
})
</script>

<style lang="scss">
#add-item-modal {
    .custom-modal-dialog-class {
        width: 274.38px;
        max-height: 90%;
        overflow: auto;
        left: 33px;
    }

    .custom-modal-body-class {
        padding: 0;
        /* width */

        ::-webkit-scrollbar {
            width: 12px;
            height: 12px;
        }

        /* Track */
        ::-webkit-scrollbar-track {
            background: #f1f1f1;
        }

        /* Handle */
        ::-webkit-scrollbar-thumb {
            background: var(--primary);
            height: 12px;
        }

        /* Handle on hover */
        ::-webkit-scrollbar-thumb:hover {
            background: #555;
        }
    }
}
</style>

<style lang="scss" scoped>
@import 'Scss/base.scss';
@import 'Scss/runtime.scss';

.selectlist__content-wrapper {
    max-height: 100%;
    position: relative;
    border-radius: 5px;
    box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
    display: block;
    background: $white;
    width: 100%;
    overflow: auto;
    border: 1px solid #e8e8e8;
    border-top: none;
}

.selectlist__content {
    display: inline-block;
    list-style: none;
    padding: 0;
    margin: 0;
    min-width: 100%;
    vertical-align: top;
}

.selectlist__element {
    display: block;
}

.selectlist__option {
    display: block;
    padding-block: 4px;
    padding-inline: 12px;
    min-height: 40px;
    line-height: 16px;
    text-decoration: none;
    text-transform: none;
    position: relative;
    cursor: pointer;
    white-space: nowrap;
}

.selectlist__option:hover {
    background-color: $primary;
    color: $white;
}

.selectlist__option--disabled {
    background: #ededed !important;
    color: #a6a6a6 !important;
    cursor: text;
    pointer-events: none;
}

.selectlist__option--group {
    display: flex;
    align-items: center;
    padding: 0.5rem 1rem;
}
</style>
