<template>
    <b-container fluid class="p-3 bg-carousel faq-carousel">
        <b-carousel controls :interval="0">
            <b-carousel-slide v-for="(page, index) in carousel" :key="index">
                <template #img>
                    <b-row>
                        <b-col v-for="document in page" :key="document.thumb" class="text-center">
                            <a :href="document.link" target="_blank">
                                <img :src="document.thumb" width="250" height="325" alt="document example" />
                            </a>
                        </b-col>
                    </b-row>
                </template>
            </b-carousel-slide>
        </b-carousel>
    </b-container>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

interface Document {
    link: string
    thumb: string
}

type Page = Document[]

export default defineComponent({
    name: 'FaqCarousel',
    props: {
        carousel: {
            type: Array<Page>,
            required: true,
        },
    },
})
</script>
