<template>
    <div class="flex flex-col w-full justify-content-center">
        <h1 class="text-center py-4">Create Your Own Worksheet</h1>
        <div class="w-full py-2 content">
            <h2>What is My Worksheet Maker?</h2>
            <div class="answer">
                My Worksheet Maker is the fast, easy, and fun way to make printable worksheets online. Quickly add and
                customize activities like open response questions, multiple choice, word scrambles, handwriting practice,
                checklists, fill in the blank, and matching.
            </div>
        </div>

        <div class="w-full py-2 content">
            <h2>What does My Worksheet Maker cost? Is it free?</h2>
            <div class="answer">
                <!-- eslint-disable prettier/prettier -->
                <strong>It is completely free to make unlimited worksheets on My Worksheet Maker</strong>. Our goal is
                to support educators around the world and we believe in providing free access to everyone
                forever. We are supported by paid plans ($5-10/month) that include
                <a :href="faqLinks.premium" target="_blank">premium features and options</a>.
                <!-- eslint-enable prettier/prettier -->
            </div>
        </div>

        <div class="w-full py-2 content">
            <h2>What will my worksheet look like?</h2>
            <div class="answer">
                As you create and customize your worksheet, the finished product is displayed on the preview above. Once it’s
                published, you’ll have a worksheet that you can print or download.
            </div>
            <div class="answer">
                Worksheets are fully customizable. Here are a few finished worksheets to get an idea of what’s possible:
            </div>
            <FaqCarousel :carousel="carousel" />
        </div>

        <div class="w-full py-2 content">
            <h2>Why choose My Worksheet Maker?</h2>
            <div class="answer">
                My Worksheet Maker is the best online worksheet builder, and here’s why:
                <ul>
                    <li>Make unlimited worksheets for free</li>
                    <li>Fully customizable worksheet maker</li>
                    <li>Easy for first-time creators</li>
                    <li>No ads</li>
                    <li>Save-as-you-go editing</li>
                    <li>Access your worksheets from any computer</li>
                    <li>Ideal for teachers</li>
                    <li>
                        Huge Community Library with over half a million
                        <a :href="faqLinks.worksheetsBrowse" target="_blank">free worksheets</a>
                        made by other educators
                    </li>
                    <li>Customer support from real humans (which we can afford because there are rarely issues)</li>
                </ul>
            </div>
        </div>

        <div class="w-full py-2 content">
            <h2>What kind of worksheets can I make?</h2>
            <div class="answer">
                With My Worksheet Maker, your worksheets are fully customizable. You can add activities including:
                <ul>
                    <li>Multiple choice</li>
                    <li>Open response</li>
                    <li>Matching</li>
                    <li>Word scrambles</li>
                    <li>True/False questions</li>
                    <li>Fill in the blank</li>
                    <li>Checklists</li>
                    <li>Handwriting practice (print and cursive)</li>
                </ul>
                <span>
                    Mix and match activities or make a full worksheet with just one type. Explore the sidebar to get started.
                </span>
            </div>
        </div>

        <div class="w-full py-2 content">
            <h2>Do you have any other builders?</h2>
            <div class="answer">
                Yes! We offer the following activity builders:
                <ul>
                    <li>
                        <a :href="brightsproutUrls.crosswords" target="_blank">My Crossword Maker</a>
                        - The fast, easy, and fun way to make your own crossword puzzles
                    </li>
                    <li>
                        <a :href="brightsproutUrls.wordsearches" target="_blank">My Word Search</a>
                        - Quickly create your own word searches with fun shapes and fonts
                    </li>
                    <li>
                        <a :href="brightsproutUrls.worksheets" target="_blank">My Worksheet Maker</a>
                        - Mix and match popular activity types for classroom printables
                    </li>
                    <li>
                        <a :href="brightsproutUrls.bingo" target="_blank">My Bingo Maker</a>
                        - Create custom bingo cards for class, game nights, and special events
                    </li>
                    <li>
                        <a :href="brightsproutUrls.flashcards" target="_blank">My Flashcard Maker</a>
                        - Make your own flashcards with helpful sizes and styles
                    </li>
                </ul>
            </div>
        </div>

        <div class="w-full py-2 content">
            <h2>What is BrightSprout?</h2>
            <div class="answer">
                BrightSprout offers the best activity makers online. It all started in 2013 with an easy online crossword
                puzzle creator called
                <a :href="brightsproutUrls.crosswords" target="_blank">My Crossword Maker</a>
                (formerly Crossword Hobbyist). Since then, we’ve added several more activity generators, starting with
                <a :href="brightsproutUrls.wordsearches" target="_blank">My Word Search</a>
                in 2015.
                <!-- eslint-disable prettier/prettier -->
                <a :href="brightsproutUrls.worksheets" target="_blank">My Worksheet Maker</a>,
                <a :href="brightsproutUrls.bingo" target="_blank">My Bingo Maker</a>, and
                <!-- eslint-enable prettier/prettier -->
                <a :href="brightsproutUrls.flashcards" target="_blank">My Flashcard Maker</a>
                were launched a few years later in 2020. You can
                <a :href="faqLinks.brightsprout" target="_blank">read more about BrightSprout</a>
                on our Knowledge Base
            </div>
        </div>

        <div class="w-full py-2 content">
            <h2>Do I own the rights to My Worksheet?</h2>
            <div class="answer">
                Yes, you do. We never use or sell your activities. You can read more about
                <a :href="faqLinks.ownership" target="_blank">ownership and commercial use</a>
                on our Knowledge Base.
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import { brightsproutUrls, faqLinks } from '../../config/Config'
import FaqCarousel from '../../components/FaqCarousel.vue'

export default defineComponent({
    name: 'WorksheetsFaqSection',
    components: {
        FaqCarousel,
    },
    data() {
        return {
            carousel: [
                [
                    {
                        link: 'https://worksheets.brightsprout.com/257137/to-kill-a-mockingbird-review',
                        thumb: '/images/to_kill_a_mockingbird_review.webp',
                    },
                    {
                        link: 'https://worksheets.brightsprout.com/257136/animal-fun',
                        thumb: '/images/animal_fun.webp',
                    },
                ],
                [
                    {
                        link: 'https://worksheets.brightsprout.com/257274/act-essay-prep-questions',
                        thumb: '/images/act_essay_prep_questions.webp',
                    },
                    {
                        link: 'https://worksheets.brightsprout.com/257134/biology-i-ch-2-test-parts-of-a-plant-cell',
                        thumb: '/images/biology_i_ch_2_test_parts_of_a_plant_cell.webp',
                    },
                ],
                [
                    {
                        link: 'https://worksheets.brightsprout.com/274376/fruit-matching',
                        thumb: '/images/fruit_matching_image.webp',
                    },
                    {
                        link: 'https://worksheets.brightsprout.com/274389/social-studies-quiz-ch-4-louisiana-purchase',
                        thumb: '/images/social_studies_quiz_ch_4_louisiana_purchase_image.webp',
                    },
                ],
                [
                    {
                        link: 'https://worksheets.brightsprout.com/274423/my-favorite-toy',
                        thumb: '/images/my_favorite_toy_image.webp',
                    },
                ],
            ],
        }
    },
    computed: {
        brightsproutUrls() {
            return brightsproutUrls
        },
        faqLinks() {
            return faqLinks
        },
    },
})
</script>
