<!-- TODO:  Fix mutation of props -->
<!-- eslint-disable vue/no-mutating-props -->
<template>
    <div>
        <div class="card-body pb-1 pt-0">
            <div>
                <div class="row justify-content-between align-items-center">
                    <div class="col-md-4">
                        <label class="mb-0 f-11 text-muted d-flex justify-content-between">
                            <div class="font-weight-bold text-nowrap">Font Size</div>
                            <div class="text-secondary">
                                {{ parseInt(itemStyle.font_size) }}
                            </div>
                        </label>
                        <b-form-input v-model="itemStyle.font_size" type="range" min="9" max="36"></b-form-input>
                    </div>

                    <div class="col-md-5">
                        <b-form-radio
                            v-model="data.settings.match_type"
                            name="type-lines"
                            class="text-muted f-13"
                            value="line"
                        >
                            <div style="height: 1.5rem; line-height: 1.5rem">Draw Lines</div>
                        </b-form-radio>
                        <b-form-radio
                            v-model="data.settings.match_type"
                            name="type-letters"
                            class="text-muted f-13"
                            value="letter"
                        >
                            <div style="height: 1.5rem; line-height: 1.5rem">Write Letters</div>
                        </b-form-radio>
                    </div>

                    <div class="col-md-3">
                        <button class="btn btn-sm btn-primary" @click="shuffleMatchings()">Shuffle</button>
                    </div>
                </div>

                <Draggable v-bind="{ handle: '.item-handle', animation: 500 }" v-model="data.pairs" class="matching-pairs">
                    <div
                        v-for="(item, pairIndex) in data.pairs"
                        :ref="`matching-pair-${pairIndex}`"
                        :key="'item-' + pairIndex"
                        class="card mt-2"
                    >
                        <div class="card-header item-handle p-1 d-flex justify-content-between align-items-center">
                            <span v-if="!pairIndex">
                                <button
                                    :id="`matching_widget_${pairIndex}`"
                                    class="btn btn-numbering d-flex justify-center align-items-center rounded-sm text-sm"
                                    @click.stop="showNumberFormat = !showNumberFormat"
                                >
                                    <NumberFormatIndex
                                        v-if="numberFormatOption(pairIndex + 1).value !== 7"
                                        :index-info="numberFormatOption(pairIndex + 1)"
                                    />

                                    <NumberFormatNoneDisplay v-else />
                                </button>

                                <b-popover
                                    :target="`matching_widget_${pairIndex}`"
                                    :show.sync="showNumberFormat"
                                    triggers=""
                                >
                                    <div v-click-outside="handleClickOutside" class="popover-body-padding-offset">
                                        <MatchingPreviewFormat
                                            class="mb-1"
                                            :item="data"
                                            :index="index"
                                            :show-premium="showPremium"
                                            style="width: 140px"
                                            @click="showNumberFormat = false"
                                        />
                                    </div>
                                </b-popover>
                            </span>
                            <button
                                v-else
                                class="btn btn-numbering d-flex justify-center align-items-center rounded-sm text-sm"
                                disabled
                            >
                                <NumberFormatIndex
                                    v-if="numberFormatOption(pairIndex + 1).value !== 7"
                                    :index-info="numberFormatOption(pairIndex + 1)"
                                />
                                <NumberFormatNoneDisplay v-else />
                            </button>
                            <svg
                                v-b-tooltip.hover
                                title="Remove"
                                class="text-danger pointer"
                                height="20"
                                width="20"
                                fill="none"
                                viewBox="0 0 24 24"
                                stroke="currentColor"
                                @click="removePair(pairIndex)"
                            >
                                <path
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                    stroke-width="2"
                                    d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16"
                                />
                            </svg>
                        </div>
                        <div class="card-body p-1">
                            <div class="position-relative word-matching">
                                <IconButton
                                    class="switch-button position-absolute"
                                    icon="UpDownArrow"
                                    size="xs"
                                    variant="secondary"
                                    style="z-index: 10"
                                    @click="swapPair(item)"
                                />
                                <b-input-group>
                                    <b-form-input
                                        :id="`worksheet-input-${pairIndex}`"
                                        v-model="item.term"
                                        type="text"
                                        class="form-control top"
                                        placeholder="Word"
                                        @change="updateData"
                                        @keydown.enter="focusDefinitionInput(pairIndex)"
                                    />
                                    <b-input-group-append>
                                        <InlineImageBtn
                                            :image-id="item.term_image"
                                            :item-index="index"
                                            :input-index="pairIndex"
                                            column="term"
                                            :btn-class="[{ 'border-bottom-0': hideBorder(item) }, 'rounded-top-right-small']"
                                            @deleted="item.term_image = ''"
                                        />
                                    </b-input-group-append>
                                </b-input-group>
                                <b-input-group>
                                    <b-form-input
                                        :id="`worksheet-input-def-${pairIndex}`"
                                        v-model="item.definition"
                                        type="text"
                                        class="form-control"
                                        placeholder="Match"
                                        @change="updateData"
                                        @keydown.enter="addNewMatchingPair"
                                    />
                                    <b-input-group-append>
                                        <InlineImageBtn
                                            class="rounded-top-right-none"
                                            :image-id="item.definition_image"
                                            :item-index="index"
                                            :input-index="pairIndex"
                                            column="definition"
                                            btn-class="rounded-bottom-right-small"
                                            @deleted="item.definition_image = ''"
                                        />
                                    </b-input-group-append>
                                </b-input-group>
                            </div>
                        </div>
                    </div>
                </Draggable>

                <div class="text-center my-2">
                    <!--                    add new-->
                    <div class="card mt-2">
                        <div class="card-body p-1">
                            <div class="position-relative word-matching">
                                <b-input-group>
                                    <b-form-input
                                        :id="`worksheet-input-new`"
                                        v-model="newTerm"
                                        type="text"
                                        class="form-control top"
                                        placeholder="Word"
                                        @keydown.enter="focusDefinitionInput(index)"
                                    />
                                    <b-input-group-append>
                                        <InlineImageBtn
                                            :item-index="index"
                                            :input-index="data.pairs.length"
                                            column="term"
                                            btn-class="border-bottom-0 rounded-top-right-small"
                                            @click="handleNewInput('term')"
                                        />
                                    </b-input-group-append>
                                </b-input-group>
                                <b-input-group>
                                    <b-form-input
                                        :id="`worksheet-input-def-new`"
                                        v-model="newDefinition"
                                        type="text"
                                        class="form-control"
                                        placeholder="Match"
                                        @keydown.enter="addNewMatchingPair"
                                    />
                                    <b-input-group-append>
                                        <InlineImageBtn
                                            :item-index="index"
                                            :input-index="data.pairs.length"
                                            column="definition"
                                            btn-class="rounded-bottom-right-small"
                                            @click="handleNewInput('definition')"
                                        />
                                    </b-input-group-append>
                                </b-input-group>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="d-flex flex-column">
                    <div
                        v-for="(fakeAnswer, fakeIndex) in data.fakeAnswers"
                        :key="fakeIndex"
                        class="d-flex flex-row fake-answer-input"
                    >
                        <b-input-group class="mb-2 mr-1">
                            <b-form-input
                                :ref="'fake_answer-' + fakeIndex"
                                v-model="data.fakeAnswers[fakeIndex].text"
                                type="text"
                                class="form-control flex-grow-1"
                                @change="$store.dispatch('document/storeDocumentState')"
                            />
                            <b-input-group-append>
                                <InlineImageBtn
                                    :item-index="index"
                                    :input-index="fakeIndex"
                                    column="fake"
                                    :image-id="data.fakeAnswers[fakeIndex].image"
                                    btn-class="rounded-right-small"
                                    @deleted="data.fakeAnswers[fakeIndex].image = ''"
                                />
                            </b-input-group-append>
                        </b-input-group>
                        <button
                            class="btn btn-danger d-flex align-items-center justify-content-center remove-fake-answer-btn"
                            tabindex="-1"
                            @click="removeFakeAnswer(fakeIndex)"
                        >
                            <svg
                                v-b-tooltip.hover
                                title="Remove"
                                class="text-white"
                                height="20"
                                width="20"
                                fill="none"
                                viewBox="0 0 24 24"
                                stroke="currentColor"
                            >
                                <path
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                    stroke-width="2"
                                    d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16"
                                />
                            </svg>
                        </button>
                    </div>
                    <b-input-group class="fake-answer-input">
                        <b-form-input class="form-control" placeholder="Fake answer" @keydown="addNewFakeAnswer($event)" />
                        <b-input-group-append>
                            <InlineImageBtn
                                :item-index="index"
                                :input-index="data.fakeAnswers.length"
                                column="fake"
                                btn-class="rounded-right-small"
                                @click="addNewFakeAnswer($event)"
                            />
                        </b-input-group-append>
                    </b-input-group>
                </div>

                <AdvancedOptionsToggleButton
                    :active="showAdvancedOptions"
                    @click="showAdvancedOptions = !showAdvancedOptions"
                />

                <div v-if="showAdvancedOptions" class="mt-2">
                    <div class="mt-2">
                        <div>
                            <div class="mt-2">
                                <div class="row justify-content-between align-items-baseline">
                                    <div
                                        v-if="data.settings.match_type !== 'letter'"
                                        class="col-md-3"
                                        style="min-width: 6rem"
                                    >
                                        <p class="f-11 font-weight-bold text-muted text-nowrap mb-0">Align Dots</p>
                                        <b-form-checkbox
                                            v-model="data.settings.line_up_dots"
                                            class="f-13 ml-1"
                                            name="check-button"
                                            :value="1"
                                            :unchecked-value="0"
                                            switch
                                            @change="updateData"
                                        ></b-form-checkbox>
                                    </div>

                                    <div class="col-md-5">
                                        <label class="font-weight-bold mb-0 f-11 text-muted">Border Thickness</label>
                                        <b-form-input
                                            v-model="itemStyle.border_width"
                                            type="range"
                                            min="0"
                                            max="10"
                                            @change="updateData"
                                        ></b-form-input>
                                    </div>

                                    <div class="col-md-3">
                                        <ColorPicker v-model="itemStyle.border_color" label="Color" @change="updateData" />
                                    </div>

                                    <div v-if="data.settings.match_type === 'letter'" class="col-md-4">
                                        <p class="font-weight-bold f-11 text-muted mb-0">Blank Position</p>
                                        <b-form-radio v-model="data.settings.blank_position" value="before">
                                            <div class="f-11" style="height: 1.5rem; line-height: 1.5rem">Before</div>
                                        </b-form-radio>
                                        <b-form-radio v-model="data.settings.blank_position" value="after">
                                            <div class="f-11" style="height: 1.5rem; line-height: 1.5rem">After</div>
                                        </b-form-radio>
                                    </div>

                                    <div class="col-md-3 py-2">
                                        <p class="f-11 font-weight-bold text-muted mb-0">Scramble</p>
                                        <b-form-checkbox
                                            v-model="data.settings.letter_scramble"
                                            class="f-13 ml-1"
                                            :value="1"
                                            :unchecked-value="0"
                                            name="check-button"
                                            switch
                                            @change="scrambleLetter"
                                        ></b-form-checkbox>
                                    </div>
                                </div>
                                <div>
                                    <label class="font-weight-bold mb-0 f-11 text-muted">Line Type</label>
                                    <div class="card p-3">
                                        <div class="d-flex w-100 row">
                                            <div class="d-flex align-items-center col-md-3">
                                                <b-form-radio
                                                    v-model="itemStyle.border_style"
                                                    name="border-style"
                                                    value="groove"
                                                    @change="updateData"
                                                ></b-form-radio>
                                                <div class="f-11 text-muted">
                                                    <div class="groove"></div>
                                                    Groove
                                                </div>
                                            </div>

                                            <div class="d-flex align-items-center col-md-3">
                                                <b-form-radio
                                                    v-model="itemStyle.border_style"
                                                    name="border-style"
                                                    value="dashed"
                                                    @change="updateData"
                                                ></b-form-radio>
                                                <div class="f-11 text-muted">
                                                    <div class="dashed"></div>
                                                    Dashed
                                                </div>
                                            </div>

                                            <div class="d-flex align-items-center col-md-3">
                                                <b-form-radio
                                                    v-model="itemStyle.border_style"
                                                    name="border-style"
                                                    value="solid"
                                                    @change="updateData"
                                                ></b-form-radio>
                                                <div class="f-11 text-muted">
                                                    <div class="solid"></div>
                                                    Solid
                                                </div>
                                            </div>

                                            <div class="d-flex align-items-center col-md-3">
                                                <b-form-radio
                                                    v-model="itemStyle.border_style"
                                                    name="border-style"
                                                    value="outset"
                                                    @change="updateData"
                                                ></b-form-radio>
                                                <div class="f-11 text-muted">
                                                    <div class="outset"></div>
                                                    Outset
                                                </div>
                                            </div>
                                        </div>
                                        <div class="d-flex w-100 mt-2 row">
                                            <div class="d-flex align-items-center col-md-3">
                                                <b-form-radio
                                                    v-model="itemStyle.border_style"
                                                    name="border-style"
                                                    value="dotted"
                                                    @change="updateData"
                                                ></b-form-radio>
                                                <div class="f-11 text-muted">
                                                    <div class="dotted"></div>
                                                    Dotted
                                                </div>
                                            </div>

                                            <div class="d-flex align-items-center col-md-3">
                                                <b-form-radio
                                                    v-model="itemStyle.border_style"
                                                    name="border-style"
                                                    value="double"
                                                    @change="updateData"
                                                ></b-form-radio>
                                                <div class="f-11 text-muted">
                                                    <div class="double"></div>
                                                    Double
                                                </div>
                                            </div>

                                            <div class="d-flex align-items-center col-md-3">
                                                <b-form-radio
                                                    v-model="itemStyle.border_style"
                                                    name="border-style"
                                                    value="ridge"
                                                    @change="updateData"
                                                ></b-form-radio>
                                                <div class="f-11 text-muted">
                                                    <div class="ridge"></div>
                                                    Ridge
                                                </div>
                                            </div>

                                            <div class="d-flex align-items-center col-md-3">
                                                <b-form-radio
                                                    v-model="itemStyle.border_style"
                                                    name="border-style"
                                                    value="inset"
                                                    @change="updateData"
                                                ></b-form-radio>
                                                <div class="f-11 text-muted">
                                                    <div class="inset"></div>
                                                    Inset
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div v-if="showInlineImagesManager" class="mt-4 images">
                            <fieldset class="fieldset">
                                <legend>IMAGES</legend>
                            </fieldset>
                            <InlineImagesManager
                                show-alignment-setting
                                show-text-placement-setting
                                show-size-setting
                                :images-settings="inlineImageSettings"
                                @change="updateImageSettings"
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Draggable from 'vuedraggable'
import MatchingItem from '../../store/models/MatchingItem'
import { mapGetters, mapState } from 'vuex'
import InlineImageBtn from '../AddInlineImageButton.vue'
import InlineImagesManager from '../../widgets/inline-images-manager.vue'
import InlineImagesMixin from '../../mixins/InlineImages'
import NumberFormatIndex from '../format-helpers/NumberFormatIndex.vue'
import { find } from 'lodash'
import { numberingFormats } from '../../objects/Document'
import MatchingPreviewFormat from '../../widgets/item-manager/components/MatchingPreviewFormat.vue'
import ClickOutside from '../../directives/click-outside'
import EditPayWall from '../../mixins/EditPayWall'
import ColorPicker from '../ColorPicker.vue'
import IconButton from '../../stories/components/buttons/IconButton.vue'
import AdvancedOptionsToggleButton from '../buttons/AdvancedOptionsToggleButton.vue'
import NumberFormatNoneDisplay from '../../widgets/item-manager/components/NumberFormatNoneDisplay.vue'

export default {
    name: 'Matching',
    components: {
        NumberFormatNoneDisplay,
        MatchingPreviewFormat,
        NumberFormatIndex,
        ColorPicker,
        IconButton,
        Draggable,
        InlineImageBtn,
        InlineImagesManager,
        AdvancedOptionsToggleButton,
    },
    directives: {
        'click-outside': ClickOutside,
    },
    mixins: [InlineImagesMixin, EditPayWall],
    props: {
        data: {
            type: Object,
            required: true,
        },
        index: {
            type: Number,
            required: true,
        },
        itemStyle: {
            type: Object,
            required: true,
        },
    },
    data() {
        return {
            content: '',
            showAdvancedOptions: false,
            instructions: [
                'Write down the answers in the space provided after each question',
                'Choose the correct answer from the options provided',
                'Trace the following texts',
                'Match each word by the left with their opposite by the right',
            ],
            newTerm: '',
            newDefinition: '',
            newTermImage: '',
            newDefitionImage: '',
            showNumberFormat: false,
            numberFormatOptions: numberingFormats,
        }
    },
    computed: {
        ...mapState(['document']),
        ...mapGetters({
            currentWidget: 'document/currentWidget',
            inlineImages: 'document/worksheetInlineImages',
            isLoggedIn: 'user/isLoggedIn',
            isScrollingSideBar: 'document/isScrollingSideBar',
        }),
        inlineImageSettings() {
            return this.data.settings?.images ?? undefined
        },
        showPremium() {
            return this.isLoggedIn && !this.hasFeature('formatting', this.document, this.document.entity_type)
        },
    },
    watch: {
        'currentWidget.focusedItem.data.target': {
            deep: true,
            handler() {
                if (this.currentWidget.focusedItem.data.target === 'term') {
                    document.getElementById(`worksheet-input-${this.currentWidget.focusedItem.data.index}`).focus()
                } else if (this.currentWidget.focusedItem.data.target === 'definition') {
                    if (typeof this.currentWidget.focusedItem.data.fake !== 'undefined') {
                        if (this.currentWidget.focusedItem.data.fake) {
                            this.$refs['fake_answer-' + this.currentWidget.focusedItem.data.index][0].focus()
                        } else {
                            document
                                .getElementById(`worksheet-input-def-${this.currentWidget.focusedItem.data.index}`)
                                .focus()
                        }
                    }
                }
            },
        },
        newTerm(val) {
            if (val.trim()) {
                this.handleNewInput('term')
            }
        },
        newDefinition(val) {
            if (val.trim()) {
                this.handleNewInput('definition')
            }
        },
        isScrollingSideBar: {
            handler() {
                this.$nextTick(() => {
                    this.handleClickOutside()
                    this.$store.dispatch('document/setIsScrollingSideBar', false)
                })
            },
            immediate: true,
        },
    },
    methods: {
        populateInstruction(value) {
            this.content = this.instructions[value]
        },
        addNewMatchingPair() {
            // eslint-disable-next-line vue/no-mutating-props
            this.data.pairs.push(new MatchingItem())
            this.$nextTick(function () {
                this.setFocus()
            })
        },
        getFocus(index) {
            return document.getElementById(`worksheet-input-${index}`)
        },
        setFocus(input = 'term') {
            let index = this.data.pairs.length - 1
            if (input == 'definition') {
                index = `def-${index}`
            }
            let elem = this.getFocus(index)
            if (elem) {
                elem.focus()
                this.$nextTick(() => {
                    const scrollContainer = document.getElementById('goto-container')
                    const lastMatchingPair = this.$refs['matching-pair-0'][0]
                    if (scrollContainer && lastMatchingPair) {
                        scrollContainer.scrollTop = scrollContainer.scrollTop + lastMatchingPair.clientHeight
                    }
                })
            }
        },
        focusDefinitionInput(index) {
            let elem = document.getElementById(`worksheet-input-def-${index}`)
            if (elem) {
                elem.focus()
            }
        },
        shuffleMatchings(times) {
            if (!times) {
                times = 5
            }

            let promises = []
            let delay = 1
            for (let i = 0; i < times; i++) {
                promises.push(
                    new Promise((resolve) => {
                        setTimeout(() => {
                            // eslint-disable-next-line vue/no-mutating-props
                            this.data.shuffle_seed = Math.floor(Math.random() * 9999)
                            resolve()
                        }, delay)
                        delay += 100
                    }),
                )
            }

            // when all the items have been saved set is_loading to false
            Promise.all(promises).then(() => {
                this.$emit('change', this.data)
            })
        },
        scrambleLetter(checked) {
            if (checked) {
                let times = 5
                let promises = []
                let delay = 1
                for (let i = 0; i < times; i++) {
                    promises.push(
                        new Promise((resolve) => {
                            setTimeout(() => {
                                this.data.pairs.forEach((pair) => {
                                    pair.shuffle = Math.floor(Math.random() * 9999)
                                })
                                resolve()
                            }, delay)
                            delay += 100
                        }),
                    )
                }

                // when all the items have been saved set is_loading to false
                Promise.all(promises).then(() => {
                    this.$emit('change', this.data)
                })
            }
        },
        swapPair(pair) {
            this.swapItem(pair)
        },
        removePair(index) {
            // eslint-disable-next-line vue/no-mutating-props
            this.data.pairs.splice(index, 1)
        },
        updateData() {
            this.$emit('change', this.data)
        },
        addNewFakeAnswer(evt) {
            if (typeof evt == 'undefined' || evt.key != 'Tab') {
                //get the fake answers length
                let index = this.data.fakeAnswers.length

                //push a fake answer
                // eslint-disable-next-line vue/no-mutating-props
                this.data.fakeAnswers.push({ text: '', image: null })

                this.$nextTick(() => {
                    this.$refs['fake_answer-' + index][0].$el.focus()
                    this.resizeDoc()
                })
            }
        },
        removeFakeAnswer(index) {
            // eslint-disable-next-line vue/no-mutating-props
            this.data.fakeAnswers.splice(index, 1)
            this.$nextTick(() => {
                this.resizeDoc()
            })
        },
        swapItem(item) {
            let term = item.term
            let termImage = item.term_image
            item.term = item.definition
            item.definition = term
            item.term_image = item.definition_image
            item.definition_image = termImage
        },
        handleNewInput(input = 'term') {
            // eslint-disable-next-line vue/no-mutating-props
            this.data.pairs.push(
                new MatchingItem(this.newTerm, this.newDefinition, this.newTermImage, this.newDefitionImage),
            )
            this.$nextTick(() => {
                this.setFocus(input)
                this.resizeDoc()
                this.newTerm = ''
                this.newDefinition = ''
                this.newTermImage = ''
                this.newDefinitionImage = ''
            })
        },
        resizeDoc() {
            this.$store.dispatch('document/scaleDocument', true)
        },
        updateImageSettings(settings) {
            // eslint-disable-next-line vue/no-mutating-props
            this.data.settings.images = settings
            this.updateData()
        },
        numberFormatOption(indexNumber) {
            if (!this.data || (!this.data.settings.left_column_format && this.data.settings.display_index))
                return {
                    number: indexNumber,
                    format: 2,
                }

            if (!this.data.settings.display_index) {
                return {
                    number: indexNumber,
                    format: 7,
                }
            }

            for (const option of this.numberFormatOptions) {
                if (option.value === this.data.settings.left_column_format) return option

                if (option.subOptions) {
                    const subOption = find(
                        option.subOptions,
                        (subOption) => parseInt(subOption.value) === this.data.settings.left_column_format,
                    )
                    if (subOption)
                        return {
                            number: indexNumber,
                            format: parseInt(subOption.value),
                        }
                }
            }

            return {
                number: indexNumber,
                format: 2,
            }
        },
        handleClickOutside() {
            this.showNumberFormat = false
        },
    },
}
</script>

<style lang="scss" scoped>
.fake-answer-input {
    padding-right: 0.34rem;
    padding-left: 0.3rem;
}
.remove-fake-answer-btn {
    z-index: 1;
    height: 38px;
    width: 47px;
    padding: 0.25rem 0.4rem;
}

// TODO: Move to global style Bootstrap variant
.btn-numbering {
    width: 2rem;
    height: 1.75rem;
    line-height: 1;
    border: 2px solid;

    &:hover {
        background-color: #efefef;
    }

    &:focus {
        box-shadow: 0 0 0 0.2rem rgba(32, 32, 32, 0.25);
    }

    &:disabled {
        border-color: #666666;
    }
}

.popover-body-padding-offset {
    padding: 0 0.5rem 0.75rem 0.75rem;
    margin: -1rem -0.25rem -0.75rem -0.5rem;
}
</style>
