<template>
    <svg width="42" height="14" viewBox="0 0 42 14" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M0 13H41.4921M0 7H41.4921M0 1H41.4921" stroke="currentColor" />
    </svg>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

export default defineComponent({
    name: 'IconStandardLines',
})
</script>
