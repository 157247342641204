<template>
    <div class="flex flex-col w-full justify-content-center">
        <h1 class="text-center py-4">Create Your Own Bingo Cards</h1>
        <div class="w-full py-2 content">
            <h2>What is My Bingo Maker?</h2>
            <div class="answer">
                My Bingo Maker is the fast, easy, and fun way to create bingo cards online. Easily add your words and watch
                the bingo maker work its magic. Customize grid size, free spaces, the word at the top, colors, and fonts. You
                can even add images for extra personalization.
            </div>
        </div>

        <div class="w-full py-2 content">
            <h2>What will my bingo set look like?</h2>
            <div class="answer">
                As you create your own bingo game, the finished product is displayed on the preview above. Once it’s
                published, you’ll have a set of bingo cards and a Call List that you can print or download.
            </div>
            <div class="answer">
                You can customize the word at the top, the number of squares, free spaces, and what appears on the call list.
            </div>
            <div class="answer">Here are a few examples showing what’s possible:</div>
            <FaqCarousel :carousel="carousel" />
        </div>

        <div class="w-full py-2 content">
            <h2>How many bingo cards do I get in a set?</h2>
            <div class="answer">
                You can have anywhere from 1 to 200 unique bingo cards in a single set. When setting up your cards, you
                choose how many you want.
            </div>
        </div>

        <div class="w-full py-2 content">
            <h2>Why Choose My Bingo Maker?</h2>
            <div class="answer">
                My Bingo Maker is the best online bingo card builder, and here’s why:
                <ul>
                    <li>Fully customizable bingo maker</li>
                    <li>Easy for first-time creators</li>
                    <li>No ads</li>
                    <li>Save-as-you-go editing</li>
                    <li>Access your bingo sets from any computer</li>
                    <li>Ideal for teachers</li>
                    <li>
                        Huge Community Library with over half a million
                        <a :href="faqLinks.bingoBrowse" target="_blank">free worksheets</a>
                        made by other educators
                    </li>
                    <li>Customer support from real humans (which we can afford because there are rarely issues)</li>
                </ul>
            </div>
        </div>

        <div class="w-full py-2 content">
            <h2>Do you have any other builders?</h2>
            <div class="answer">
                Yes! We offer the following activity builders:
                <ul>
                    <li>
                        <a :href="brightsproutUrls.crosswords" target="_blank">My Crossword Maker</a>
                        - The fast, easy, and fun way to make your own crossword puzzles
                    </li>
                    <li>
                        <a :href="brightsproutUrls.wordsearches" target="_blank">My Word Search</a>
                        - Quickly create your own word searches with fun shapes and fonts
                    </li>
                    <li>
                        <a :href="brightsproutUrls.worksheets" target="_blank">My Worksheet Maker</a>
                        - Mix and match popular activity types for classroom printables
                    </li>
                    <li>
                        <a :href="brightsproutUrls.bingo" target="_blank">My Bingo Maker</a>
                        - Create custom bingo cards for class, game nights, and special events
                    </li>
                    <li>
                        <a :href="brightsproutUrls.flashcards" target="_blank">My Flashcard Maker</a>
                        - Make your own flashcards with helpful sizes and styles
                    </li>
                </ul>
            </div>
        </div>

        <div class="w-full py-2 content">
            <h2>What is BrightSprout?</h2>
            <div class="answer">
                BrightSprout offers the best activity makers online. It all started in 2013 with an easy online crossword
                puzzle creator called
                <a :href="brightsproutUrls.crosswords" target="_blank">My Crossword Maker</a>
                (formerly Crossword Hobbyist). Since then, we’ve added several more activity generators, starting with
                <a :href="brightsproutUrls.wordsearches" target="_blank">My Word Search</a>
                in 2015.
                <!-- eslint-disable prettier/prettier -->
                <a :href="brightsproutUrls.worksheets" target="_blank">My Worksheet Maker</a>,
                <a :href="brightsproutUrls.bingo" target="_blank">My Bingo Maker</a>, and
                <!-- eslint-enable prettier/prettier -->
                <a :href="brightsproutUrls.flashcards" target="_blank">My Flashcard Maker</a>
                were launched a few years later in 2020. You can
                <a :href="faqLinks.brightsprout" target="_blank">read more about BrightSprout</a>
                on our Knowledge Base
            </div>
        </div>

        <div class="w-full py-2 content">
            <h2>What does My Bingo Maker cost?</h2>
            <div class="answer">
                A subscription to make unlimited bingo costs $5/month, or $4.25/month when billed annually. You can try it
                free for 30 days before deciding if you want to subscribe. You can also purchase individual bingo activities
                for special events.
            </div>
        </div>

        <div class="w-full py-2 content">
            <h2>Many things on the internet are free. Why not this?</h2>
            <div class="answer">
                In general, it’s possible to be the best or the cheapest but not both. We chose to be the best. We respect
                that many people prefer the cheapest, and this may not be the site for them. But if you want the best, you’ve
                come to the right place.
            </div>
        </div>

        <div class="w-full py-2 content">
            <h2>Can I keep my bingo sets private?</h2>
            <div class="answer">
                Yes. Whether you purchase an individual bingo activity or have a recurring plan, there is a checkbox option
                on the publishing screen to save bingo sets as ‘private’ so only you can see them.
            </div>
        </div>

        <div class="w-full py-2 content">
            <h2>Do I own the rights to my bingo set?</h2>
            <div class="answer">
                Yes, you do. We never use or sell your activities. You can read more about
                <a :href="faqLinks.ownership" target="_blank">ownership and commercial use</a>
                on our Knowledge Base.
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import { brightsproutUrls, faqLinks } from '../../config/Config'
import FaqCarousel from '../../components/FaqCarousel.vue'

export default defineComponent({
    name: 'BingoFaqSection',
    components: {
        FaqCarousel,
    },
    data() {
        return {
            carousel: [
                [
                    {
                        link: 'https://bingo.brightsprout.com/257666/musician-bingo-find-your-favorites',
                        thumb: '/images/musician_bingo_find_your_favorites.webp',
                    },
                    {
                        link: 'https://bingo.brightsprout.com/257662/tutti-frutti',
                        thumb: '/images/tutti_frutti.webp',
                    },
                ],
                [
                    {
                        link: 'https://bingo.brightsprout.com/257658/all-about-whales-bingo',
                        thumb: '/images/all_about_whales_bingo.webp',
                    },
                    {
                        link: 'https://bingo.brightsprout.com/275503/musical-instruments-around-the-world',
                        thumb: '/images/portuguese_vocab_bingo_image.webp',
                    },
                ],
                [
                    {
                        link: 'https://bingo.brightsprout.com/275514/at-my-house',
                        thumb: '/images/at_my_house_image.webp',
                    },
                    {
                        link: 'https://bingo.brightsprout.com/275511/portuguese-vocab-bingo',
                        thumb: '/images/portuguese_vocab_bingo_image.webp',
                    },
                ],
            ],
        }
    },
    computed: {
        brightsproutUrls() {
            return brightsproutUrls
        },
        faqLinks() {
            return faqLinks
        },
    },
})
</script>
