<template>
    <svg width="42" height="6" viewBox="0 0 42 6" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M29.7955 5.89773L16.7955 5.89773V4.89773L29.7955 4.89773V5.89773Z"
            fill="currentColor"
        />
        <path
            d="M15.7912 1.89773H15.0866C15.045 1.69508 14.9721 1.51705 14.8679 1.36364C14.7656 1.21023 14.6406 1.08144 14.4929 0.977273C14.3471 0.871212 14.1851 0.791667 14.0071 0.738636C13.8291 0.685606 13.6435 0.659091 13.4503 0.659091C13.098 0.659091 12.7789 0.748106 12.4929 0.926136C12.2088 1.10417 11.9825 1.36648 11.8139 1.71307C11.6473 2.05966 11.5639 2.48485 11.5639 2.98864C11.5639 3.49242 11.6473 3.91761 11.8139 4.2642C11.9825 4.6108 12.2088 4.87311 12.4929 5.05114C12.7789 5.22917 13.098 5.31818 13.4503 5.31818C13.6435 5.31818 13.8291 5.29167 14.0071 5.23864C14.1851 5.18561 14.3471 5.10701 14.4929 5.00284C14.6406 4.89678 14.7656 4.76705 14.8679 4.61364C14.9721 4.45833 15.045 4.2803 15.0866 4.07955H15.7912C15.7382 4.37689 15.6416 4.64299 15.5014 4.87784C15.3613 5.11269 15.187 5.3125 14.9787 5.47727C14.7704 5.64015 14.5365 5.7642 14.277 5.84943C14.0194 5.93466 13.7438 5.97727 13.4503 5.97727C12.9541 5.97727 12.5128 5.85606 12.1264 5.61364C11.7401 5.37121 11.4361 5.02652 11.2145 4.57955C10.9929 4.13258 10.8821 3.60227 10.8821 2.98864C10.8821 2.375 10.9929 1.8447 11.2145 1.39773C11.4361 0.950758 11.7401 0.606061 12.1264 0.363636C12.5128 0.121212 12.9541 0 13.4503 0C13.7438 0 14.0194 0.0426137 14.277 0.127841C14.5365 0.213068 14.7704 0.338068 14.9787 0.502841C15.187 0.66572 15.3613 0.864583 15.5014 1.09943C15.6416 1.33239 15.7382 1.59848 15.7912 1.89773Z"
            fill="currentColor"
        />
        <path
            d="M5.90625 5.89773V0.0795455H7.94034C8.34564 0.0795455 8.67992 0.149621 8.94318 0.289773C9.20644 0.42803 9.40246 0.614583 9.53125 0.849432C9.66004 1.08239 9.72443 1.34091 9.72443 1.625C9.72443 1.875 9.67992 2.08144 9.59091 2.24432C9.50379 2.4072 9.38826 2.53598 9.24432 2.63068C9.10227 2.72538 8.94792 2.79545 8.78125 2.84091V2.89773C8.95928 2.90909 9.13826 2.97159 9.31818 3.08523C9.49811 3.19886 9.64867 3.36174 9.76989 3.57386C9.8911 3.78598 9.9517 4.04545 9.9517 4.35227C9.9517 4.64394 9.88542 4.90625 9.75284 5.1392C9.62026 5.37216 9.41098 5.55682 9.125 5.69318C8.83902 5.82955 8.46686 5.89773 8.00852 5.89773H5.90625ZM6.6108 5.27273H8.00852C8.46875 5.27273 8.79545 5.18371 8.98864 5.00568C9.18371 4.82576 9.28125 4.60795 9.28125 4.35227C9.28125 4.1553 9.23106 3.97349 9.13068 3.80682C9.0303 3.63826 8.88731 3.50379 8.7017 3.40341C8.5161 3.30114 8.2964 3.25 8.04261 3.25H6.6108V5.27273ZM6.6108 2.63636H7.91761C8.12973 2.63636 8.32102 2.5947 8.49148 2.51136C8.66383 2.42803 8.80019 2.31061 8.90057 2.15909C9.00284 2.00758 9.05398 1.82955 9.05398 1.625C9.05398 1.36932 8.96496 1.15246 8.78693 0.974432C8.6089 0.794508 8.3267 0.704545 7.94034 0.704545H6.6108V2.63636Z"
            fill="currentColor"
        />
        <path
            d="M0.738636 5.89773H0L2.13636 0.0795455H2.86364L5 5.89773H4.26136L2.52273 1H2.47727L0.738636 5.89773ZM1.01136 3.625H3.98864V4.25H1.01136V3.625Z"
            fill="currentColor"
        />
    </svg>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

export default defineComponent({
    name: 'IconShortLine',
})
</script>
