<template>
    <div class="card-body py-1">
        <div @drop="(e) => e.preventDefault()">
            <VueEditor
                :ref="`widget_${index}`"
                v-model="instructionInput"
                :editor-toolbar="customToolbar"
                :editor-options="editorSettings"
                placeholder="Write your basic text."
                @text-change="updateData"
            ></VueEditor>
        </div>
    </div>
</template>

<script>
import { defineComponent } from 'vue'
import { VueEditor } from 'vue2-editor'

export default defineComponent({
    name: 'BasicText',
    components: {
        VueEditor,
    },
    props: {
        data: {
            type: Object,
            required: true,
        },
        index: {
            type: Number,
            required: true,
        },
    },
    data() {
        return {
            customToolbar: [['bold', 'italic', 'underline'], [], []],
            editorSettings: {
                formats: ['bold', 'italic', 'underline', 'script'],
            },
            itemData: {
                ...this.data,
            },
        }
    },
    computed: {
        instructionInput: {
            get() {
                return this.itemData.instruction
            },
            set(value) {
                this.itemData.instruction = value
            },
        },
    },
    mounted() {
        this.$refs[`widget_${this.index}`].quill.focus()
    },
    methods: {
        updateData() {
            this.$emit('change', this.itemData)
        },
    },
})
</script>
