<template>
    <div class="flex flex-col w-full justify-content-center">
        <h1 class="text-center py-4">Create Your Own Flashcards</h1>
        <div class="w-full py-2 content">
            <h2>What is My Flashcard Maker?</h2>
            <div class="answer">
                My Flashcard Maker is the fast, easy, and fun way to create flashcards online. Quickly customize your
                flashcard size, orientation, and content. Create side-by-side flashcards, double-sided flashcards, cut-outs,
                3x5 cards, flashcards with images, and more. You can fill in both sides or leave them blank for students to
                complete.
            </div>
        </div>

        <div class="w-full py-2 content">
            <h2>What will my flashcards look like?</h2>
            <div class="answer">
                As you create and customize your flashcard, the finished product is displayed on the preview above. Once it’s
                published, you’ll have a set of flashcards you can print or download.
            </div>
            <div class="answer">
                Flashcards are fully customizable. Here are a few finished flashcards to get an idea of what’s possible:
            </div>
            <FaqCarousel :carousel="carousel" />
        </div>

        <div class="w-full py-2 content">
            <h2>Why choose My Flashcard Maker?</h2>
            <div class="answer">
                My Flashcard Maker is the best online flashcard builder, and here’s why:
                <ul>
                    <li>Fully customizable flashcard maker</li>
                    <li>Easy for first-time creators</li>
                    <li>No ads</li>
                    <li>Save-as-you-go editing</li>
                    <li>Access your flashcards from any computer</li>
                    <li>Ideal for teachers</li>
                    <li>
                        Huge Community Library with over half a million
                        <a :href="faqLinks.flashcardsBrowse" target="_blank">free worksheets</a>
                        made by other educators
                    </li>
                    <li>
                        Excellent customer support from real humans (which we can afford because there are rarely issues)
                    </li>
                </ul>
            </div>
        </div>

        <div class="w-full py-2 content">
            <h2>Do you have any other builders?</h2>
            <div class="answer">
                Yes! We offer the following activity builders:
                <ul>
                    <li>
                        <a :href="brightsproutUrls.crosswords" target="_blank">My Crossword Maker</a>
                        - The fast, easy, and fun way to make your own crossword puzzles
                    </li>
                    <li>
                        <a :href="brightsproutUrls.wordsearches" target="_blank">My Word Search</a>
                        - Quickly create your own word searches with fun shapes and fonts
                    </li>
                    <li>
                        <a :href="brightsproutUrls.worksheets" target="_blank">My Worksheet Maker</a>
                        - Mix and match popular activity types for classroom printables
                    </li>
                    <li>
                        <a :href="brightsproutUrls.bingo" target="_blank">My Bingo Maker</a>
                        - Create custom bingo cards for class, game nights, and special events
                    </li>
                    <li>
                        <a :href="brightsproutUrls.flashcards" target="_blank">My Flashcard Maker</a>
                        - Make your own flashcards with helpful sizes and styles
                    </li>
                </ul>
            </div>
        </div>

        <div class="w-full py-2 content">
            <h2>What is BrightSprout?</h2>
            <div class="answer">
                BrightSprout offers the best activity makers online. It all started in 2013 with an easy online crossword
                puzzle creator called
                <a :href="brightsproutUrls.crosswords" target="_blank">My Crossword Maker</a>
                (formerly Crossword Hobbyist). Since then, we’ve added several more activity generators, starting with
                <a :href="brightsproutUrls.wordsearches" target="_blank">My Word Search</a>
                in 2015.
                <!-- eslint-disable prettier/prettier -->
                <a :href="brightsproutUrls.worksheets" target="_blank">My Worksheet Maker</a>,
                <a :href="brightsproutUrls.bingo" target="_blank">My Bingo Maker</a>, and
                <!-- eslint-enable prettier/prettier -->
                <a :href="brightsproutUrls.flashcards" target="_blank">My Flashcard Maker</a>
                were launched a few years later in 2020. You can
                <a :href="faqLinks.brightsprout" target="_blank">read more about BrightSprout</a>
                on our Knowledge Base
            </div>
        </div>

        <div class="w-full py-2 content">
            <h2>What does My Flashcard Maker Cost?</h2>
            <div class="answer">
                A subscription to make unlimited flashcards costs $5/month, or $4.25/month when billed annually. You can try
                it free for 30 days before deciding if you want to subscribe. Or, you can also purchase individual flashcard
                activities.
            </div>
        </div>

        <div class="w-full py-2 content">
            <h2>Many things on the internet are free. Why not this?</h2>
            <div class="answer">
                In general, it’s possible to be the best or the cheapest but not both. We chose to be the best. We respect
                that many people prefer the cheapest, and this may not be the site for them. But if you want the best, you’ve
                come to the right place.
            </div>
        </div>

        <div class="w-full py-2 content">
            <h2>Can I keep my flashcards private?</h2>
            <div class="answer">
                Yes. Whether you purchase an individual flashcard activity or have a recurring plan, there is a checkbox
                option on the publishing screen to save flashcards as ‘Private’ so only you can see them.
            </div>
        </div>

        <div class="w-full py-2 content">
            <h2>Do I own the rights to my flashcards?</h2>
            <div class="answer">
                Yes, you do. We never use or sell your activities. You can read more about
                <a :href="faqLinks.ownership" target="_blank">ownership and commercial use</a>
                on our Knowledge Base.
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import { brightsproutUrls, faqLinks } from '../../config/Config'
import FaqCarousel from '../../components/FaqCarousel.vue'

export default defineComponent({
    name: 'FlashcardsFaqSection',
    components: {
        FaqCarousel,
    },
    data() {
        return {
            carousel: [
                [
                    {
                        link: 'https://flashcards.brightsprout.com/257672/chinese-history-dynasties',
                        thumb: '/images/chinese_history_dynasties.webp',
                    },
                    {
                        link: 'https://flashcards.brightsprout.com/257667/animals-in-portuguese',
                        thumb: '/images/animals_in_portuguese.webp',
                    },
                ],
                [
                    {
                        link: 'https://flashcards.brightsprout.com/275521/us-state-facts-and-official-insignia',
                        thumb: '/images/us_state_facts_and_official_insignia_image.webp',
                    },
                    {
                        link: 'https://flashcards.brightsprout.com/275517/musical-symbols',
                        thumb: '/images/musical_symbols_image.webp',
                    },
                ],
                [
                    {
                        link: 'https://flashcards.brightsprout.com/275525/daily-sketch-practice',
                        thumb: '/images/daily_sketch_practice_image.webp',
                    },
                    {
                        link: 'https://flashcards.brightsprout.com/275526/common-astronomy-terms',
                        thumb: '/images/common_astronomy_terms_image.webp',
                    },
                ],
            ],
        }
    },
    computed: {
        brightsproutUrls() {
            return brightsproutUrls
        },
        faqLinks() {
            return faqLinks
        },
    },
})
</script>
