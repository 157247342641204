<template>
    <div class="faq-section pt-2">
        <hr />
        <Component :is="content" />
    </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import { mapGetters } from 'vuex'

import WorksheetsFaqSection from './WorksheetsFaqSection.vue'
import FlashcardsFaqSection from './FlashcardsFaqSection.vue'
import BingoFaqSection from './BingoFaqSection.vue'

export default defineComponent({
    name: 'FaqSection',
    computed: {
        ...mapGetters('document', ['entityType']),
        content() {
            switch (this.entityType) {
                case 'bingo':
                    return BingoFaqSection
                case 'flashcard':
                    return FlashcardsFaqSection
                default:
                    return WorksheetsFaqSection
            }
        },
    },
})
</script>
