<template>
    <i style="color: rgba(0, 0, 0, 0.4)">#</i>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

export default defineComponent({
    name: 'NumberFormatNoneDisplay',
})
</script>
