<template>
    <div class="card-body p-2">
        <div class="d-flex align-items-center mb-2">
            <StartOverButton @startOver="onClearAll" />
            <b-form-select v-model="itemSort" class="mx-2" size="sm" @change="sortItems">
                <b-form-select-option :value="{ field: false, dir: false }">-- Sort By --</b-form-select-option>

                <b-form-select-option :value="{ field: 'term', dir: 'asc' }">
                    {{ $lang.get('sort_term_a_z') }}
                </b-form-select-option>

                <b-form-select-option :value="{ field: 'term', dir: 'desc' }">
                    {{ $lang.get('sort_term_z_a') }}
                </b-form-select-option>

                <b-form-select-option :value="{ field: 'definition', dir: 'asc' }">
                    {{ $lang.get('sort_definition_a_z') }}
                </b-form-select-option>

                <b-form-select-option :value="{ field: 'definition', dir: 'desc' }">
                    {{ $lang.get('sort_definition_z_a') }}
                </b-form-select-option>

                <b-form-select-option :value="{ field: 'randomize' }">Random</b-form-select-option>
            </b-form-select>
            <IconButton
                v-b-tooltip.hover
                icon="UpDownArrow"
                size="sm"
                title="Flip All"
                variant="secondary"
                @click="handleFlipAll"
            />
            <b-button
                v-show="false"
                v-b-tooltip.hover
                title="Upload CSV"
                class="text-nowrap overflow-visible ml-2"
                size="sm"
                variant="success"
            >
                Upload CSV
            </b-button>
        </div>

        <ItemsForm />

        <div v-if="showInlineImagesManager" class="mt-4 mb-1 images">
            <fieldset class="fieldset">
                <legend>IMAGES</legend>
            </fieldset>
            <InlineImagesManager
                show-alignment-setting
                show-text-placement-setting
                show-size-setting
                :images-settings="inlineImageSettings"
                @change="updateImageSettings"
            />
        </div>
    </div>
</template>

<script>
import { defineComponent } from 'vue'
import ItemsForm from '../items-form.vue'
import { mapGetters } from 'vuex'
import wmAlert from '../../helpers/wmAlerts'
import InlineImagesManager from '../../widgets/inline-images-manager.vue'
import InlineImagesMixin from '../../mixins/InlineImages'
import IconButton from '../../stories/components/buttons/IconButton.vue'
import StartOverButton from '../StartOverButton.vue'

export default defineComponent({
    name: 'WordsDefinition',
    components: {
        IconButton,
        StartOverButton,
        ItemsForm,
        InlineImagesManager,
    },
    mixins: [InlineImagesMixin],
    props: {
        data: {
            type: Object,
            default: () => ({}),
        },
    },
    data() {
        return {
            itemSort: { field: 'term', dir: 'asc' },
            itemData: {
                ...this.data,
            },
        }
    },
    computed: {
        ...mapGetters({
            items: 'document/documentItems',
            style: 'document/documentStyle',
        }),
        documentItems: {
            get() {
                return this.items
            },
            set(values) {
                // for resorting
                this.$store.dispatch('document/dragSortItems', values)
            },
        },
        inlineImageSettings() {
            return this.style?.images ?? undefined
        },
    },
    created() {
        this.resetItemSort()
    },
    methods: {
        resetItemSort() {
            this.itemSort = { field: false, dir: false }
        },
        sortItems() {
            if (this.itemSort.field) {
                if (this.itemSort.field == 'randomize') {
                    this.$store.dispatch('document/randomizeItems')
                } else {
                    this.sortItemList(this.itemSort.field, this.itemSort.dir)
                }
            }
        },
        sortItemList(field, direction) {
            this.$store.dispatch('document/sortItems', {
                field: field,
                direction: direction,
            })
        },
        updateData() {
            this.$emit('change', this.itemData)
        },
        onClearAll() {
            wmAlert
                .confirm({
                    title: 'Are you sure you want to delete all questions?',
                    html: 'This will revert your document to a blank template and cannot be undone.',
                    confirmButtonText: 'Clear All',
                })
                .then((result) => {
                    if (result.isConfirmed) {
                        // clear words definitions
                        this.$store.dispatch('document/clearItems')
                    }
                })
        },
        handleFlipAll() {
            const queues = []
            for (let i = 0; i < this.documentItems.length; i++) {
                queues.push(this.$store.dispatch('document/swapItem', i))
            }
            Promise.all(queues)
        },
        async updateImageSettings(settings) {
            try {
                await this.$store.dispatch('document/setFlashcardImageSettings', settings)
                this.updateData()
                await this.$store.dispatch('document/storeDocumentState')
            } catch (error) {
                console.error('Flashcard: Failed to update image settings: ', error)
            }
        },
    },
})
</script>

<style scoped>
.overflow-visible {
    overflow: unset;
}
</style>
